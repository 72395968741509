<template>
  <div id="smallModal">
    <CModal
      :show.sync="showModal"
      :closeOnBackdrop="false"
      :centered="true"
      size="sm"
      :title="$t('SMALL_MODAL_COMPONENT_TITLE_MODAL')"
      color="#52a3e8eb"
    >
      <h6 class="code-text">{{ content }}</h6>
      <CInput class="input-verification-code" @keyup.enter="actionHandler" v-model="inputValue" v-if="isShowInput" autofocus />
      <template #header>
        <h5 class="modal-title">{{ title }}</h5>
        <CButtonClose @click="closeModal" class="text-white" />
      </template>
      <template #footer>
        <CButton class="btn-verify" @click="actionHandler">{{btnLabel}}</CButton>
      </template>
    </CModal>
  </div>
</template>

<script>
export default {
  props: {
    showModal: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: '',
    },
    content: {
      type: String,
      default: '',
    },
    btnLabel: {
      type: String,
      default: '',
    },
    action: {
      type: String,
      default: '',
    },
    isShowInput: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      inputValue: '',
    };
  },
  methods: {
    actionHandler() {
      if (this.action === 'VERIFY') {
        this.$emit('verify-code', this.inputValue);
        this.closeModal();
      } else {
        this.closeModal();
      }
    },

    closeModal() {
      this.inputValue = '';
      this.$emit('close-modal');
    },
  },
  name: 'SmallModalComponent',
};
</script>

<style lang="scss">
#smallModal /deep/ {
  .modal-header {
    background: #0f85ea;
    padding: 8px 16px;
    color: #fff;
  }
  .modal-body {
    padding: 16px;
  }
  .modal-content {
    .code-text {
      font-size: 15px;
      margin-top: 8px;
    }
    .content-forget-password-step2 {
      text-align: center;
      margin-left: 0 !important;
    }
    .input-verification-code {
      justify-content: center;
      margin-top: 15px;
    }
  }
  .modal-footer {
    margin-top: -25px;
    border-top: none;
    justify-content: center;
    .btn-verify {
      padding-left: 17px;
      padding-right: 17px;
      color: white;
      background: #0a51bb;
    }
    .btn-forgot-pass-step2 {
    }
  }
}
</style>
