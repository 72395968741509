<template>
  <div>
    <ChangeLanguage class="change-language-container-login-page" />
    <LoginFormComponent
      ref="loginFormComponent"
      formType="LOGIN"
      :formTitle="$t('LOGIN_PAGE_TITLE_FORM_LOGIN')"
      :btnLabel="$t('LOGIN_PAGE_BUTTON_LOGIN')"
      :label1="$t('LOGIN_PAGE_LABEL_EMAIL')"
      :label2="$t('LOGIN_PAGE_LABEL_PASSWORD')"
      :text6="$t('LOGIN_PAGE_TEXT_EMAIL_ADDRESS')"
      :text7="$t('LOGIN_PAGE_TEXT_PASSWORD')"
      :rulesEmail="true"
      :rulesRequired="true"
      @login="login"
      :siteKey="siteKey"
    />

    <SmallModalComponent
      @close-modal="onCloseModal"
      @verify-code="verifyCode"
      :showModal="showModal"
      :title="modalTitle"
      :content="modalContent"
      :btnLabel="btnLabel"
      :action="modalAction"
      :isShowInput="isShowInput"
    />

    <SmallModalComponent
      @close-modal="onCloseConfirmationIpModal"
      :showModal="isShownComfirmIpModal"
      :title="$t('LOGIN_PAGE_TITLE_MODAL_NEW_IP_CONFIRMATION')"
      :content="$t('LOGIN_PAGE_CONTENT_MODAL_NEW_IP_CONFIRMATION')"
      :btnLabel="$t('LOGIN_PAGE_BUTTON_MODAL_NEW_IP_CONFIRMATION')"
      :isShowInput="false"
    />
  </div>
</template>

<script>
import _ from 'lodash';
import { mapState } from 'vuex';
import SmallModalComponent from '@/components/SmallModalComponent';
import LoginFormComponent from '@/components/LoginFormComponent';
import ChangeLanguage from '@/components/ChangeLanguage';
import { AUTH_REQUEST, AUTH_TWOFA_CONFIRM } from '@/store/actions/auth';
import responseHelper from '@/utils/response-helper';
import { env } from '@/constants';

const isDisabledCaptcha = env.recaptcha.isDisabled;

export default {
  components: {
    LoginFormComponent,
    SmallModalComponent,
    ChangeLanguage,
  },
  data() {
    return {
      redirect: '/admin',
      showModal: false,
      modalTitle: this.$t('LOGIN_PAGE_TITLE_MODAL_TWO_FA'),
      modalContent: this.$t('LOGIN_PAGE_TEXT_MODAL_CONTENT'),
      btnLabel: this.$t('LOGIN_PAGE_BUTTON_VERIFY'),
      modalAction: 'VERIFY',
      isShowInput: true,
      email: '',
      password: '',
      captchaToken: '',
      siteKey: env.recaptcha.key,
      isLogin: false,
      isShownComfirmIpModal: false,
    };
  },
  computed: {
    ...mapState({
      has2FA: (state) => state.auth.twofaVerify.twofa,
      isComfirmedIp: (state) => state.auth.isComfirmedIp,
    }),
  },
  mounted() {
    let redirectUrl = _.trim(this.$route.query.redirectUrl);
    if (!redirectUrl.startsWith('/')) {
      redirectUrl = '';
    }

    this.redirect = redirectUrl || '/admin';
  },
  methods: {
    verifyCode(verifyCode) {
      this.$store.dispatch(AUTH_TWOFA_CONFIRM, {
        code: verifyCode,
        redirectUrl: this.redirect,
      });
    },

    login({ email, password, captchaToken }) {
      if (!isDisabledCaptcha && !captchaToken) {
        return;
      }

      this.authenticate(email, password, captchaToken);
    },
    async authenticate(email, password, captchaToken) {
      // console.log('file: Login.vue ~ line 106 ~ authenticate ~ captchaToken', captchaToken);   return;
      const data = {
        email,
        password,
        'g-recaptcha-response': captchaToken,
        redirect: this.redirect,
      };
      if (isDisabledCaptcha) {
        delete data['g-recaptcha-response'];
      }

      this.$store.dispatch(AUTH_REQUEST, data).then(() => {
        this.isLogin = true;

        if (this.isComfirmedIp) {
          this.isShownComfirmIpModal = true;
          return;
        }

        if (this.has2FA) {
          this.showModal = true;
        }
      });
    },
    onCloseModal() {
      this.isShowInput = true;
      this.showModal = false;
    },
    onCloseConfirmationIpModal() {
      this.isShownComfirmIpModal = false;
    },
    setRedirect(url) {
      this.redirect = (url && url.fullPath) || '/admin';
    },
  },
  name: 'Login',
};
</script>

<style lang="scss">
/* Change the white to any color ;) */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px white inset !important;
}
</style>
